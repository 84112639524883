import { toArray } from "./utils";

const NOTIFICATION = ".js-notification";
const CLOSE_BTN = ".js-notification-close";

export default class {
  constructor() {
    const notifications = document.querySelectorAll(NOTIFICATION);
    toArray(notifications).forEach((notification) => {
      const closeButton = notification.querySelector(CLOSE_BTN);
      if (closeButton) {
        this.bindCloseEvent(closeButton, notification);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  bindCloseEvent(button, notification) {
    const { height } = notification.getBoundingClientRect();
    button.addEventListener("click", () => {
      notification.style.height = `${height}px`;
      setTimeout(() => {
        notification.classList.add("is-hidden");
        window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search,
        );
      });
    });
  }
}
