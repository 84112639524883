import { toArray } from "./utils";

export default class {
  constructor() {
    this.news = document.querySelector(".js-news");
    this.buttons = toArray(document.querySelectorAll(".js-news-close"));

    this.setupLinks();
    this.setupClose();
  }

  setupLinks() {
    if (!this.news) {
      return;
    }

    toArray(this.news.querySelectorAll("a")).forEach((link) => {
      link.target = "_blank";
    });
  }

  setupClose() {
    this.buttons.forEach((btn) => {
      btn.addEventListener("click", () => {
        const { key, hash } = btn.dataset;
        localStorage.setItem(key, hash);
        document.querySelector(`.js-news[data-key="${key}"]`).style.display =
          "none";
      });
    });
  }
}
