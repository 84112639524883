import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const OPEN_DELAY = 10; // seconds; setting to 0 may negatively impact page speed index

const isIosDevice =
  typeof window !== "undefined" &&
  window.navigator &&
  window.navigator.platform &&
  /iP(ad|hone|od)/.test(window.navigator.platform);

const localStorageKey = "home-newsletter-modal-closed";

export default class NewsletterModal {
  constructor() {
    this.modal = document.querySelector(".js-newsletter-modal");
    this.modalClose = document.querySelector(".js-newsletter-modal-close");
    this.modalScrollable = document.querySelector(
      ".js-newsletter-modal-scrollable",
    );
    this.modalFormContainer = document.querySelector(
      ".js-newsletter-modal-form-container",
    );
    this.form = document.querySelector(".js-newsletter-form");
    this.formForm = document.querySelector(".js-newsletter-form-form");
    this.formForm.dataset.modal = "false";
    this.formOriginalParent = this.form.parentElement;

    if (
      !(
        this.modal &&
        this.modalClose &&
        this.modalScrollable &&
        this.form &&
        this.formForm &&
        this.modalFormContainer
      )
    ) {
      return;
    }

    this.setupClose();
    this.setupAutoClose();
    this.openIfNecessary();
  }

  get isOpen() {
    return this.modal.classList.contains("is-open");
  }

  setupClose() {
    this.modalClose.addEventListener("click", () => this.close());
  }

  setupAutoClose() {
    document.addEventListener("modal-form-submitted", () => {
      this.close();
    });
  }

  open() {
    if (this.isOpen) {
      return;
    }

    this.formForm.dataset.modal = "true";
    this.modalFormContainer.appendChild(this.form);

    if (isIosDevice) {
      disableBodyScroll(this.modalScrollable);
    } else {
      const scrollBarGap =
        window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = "hidden";
      if (scrollBarGap > 0) {
        document.body.style.paddingRight = `${scrollBarGap}px`;
      }
    }

    this.modal.classList.toggle("is-open");
  }

  close() {
    if (!this.isOpen) {
      return;
    }

    if (isIosDevice) {
      enableBodyScroll(this.modalScrollable);
    } else {
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    }

    this.modal.classList.toggle("is-open");
    this.formForm.dataset.modal = "false";
    this.formOriginalParent.appendChild(this.form);

    window.localStorage.setItem(localStorageKey, 1);
  }

  openIfNecessary() {
    if (!window.localStorage.getItem(localStorageKey)) {
      setTimeout(() => {
        this.open();
      }, OPEN_DELAY * 1000);
    }
  }
}
