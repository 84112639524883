// Based on https://github.com/netlify/netlify-identity-widget/blob/4edc7fca07cc242b24c2fd3a42fde97f91b6a38d/src/netlify-identity.js#L147
const routes = /(confirmation|invite|recovery|email_change)_token=([^&]+)/;
const errorRoute = /error=access_denied&error_description=403/;
const accessTokenRoute = /access_token=/;

export default class {
  constructor() {
    const hash = (document.location.hash || "").replace(/^#\/?/, "");

    if (!hash) {
      return;
    }

    if (
      hash.match(routes) ||
      hash.match(errorRoute) ||
      hash.match(accessTokenRoute)
    ) {
      document.location.pathname = "/admin/";
    }
  }
}
