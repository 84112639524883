import { toArray } from "./utils";

export default class {
  constructor() {
    this.data = {
      tops: toArray(document.querySelectorAll(".js-job-top")),
      bottoms: toArray(document.querySelectorAll(".js-job-bottom")),
    };
    this.init();
  }

  // eslint-disable-next-line class-methods-use-this
  getMeasures(array) {
    return array.map((item) => {
      item.style.height = "auto";
      const height = item.offsetHeight;
      item.style.height = "0";
      return { item, height };
    });
  }

  jobOffers() {
    this.data.tops.forEach((item) => {
      item.classList.remove("is-opened");
    });
    const data = this.getMeasures(this.data.bottoms);

    this.data.tops.forEach((jobTop) => {
      jobTop.addEventListener("click", function () {
        const id = this.getAttribute("js-id");
        const related = data.filter(function (obj) {
          return obj.item.getAttribute("js-id") === id;
        })[0];
        if (related.item.offsetHeight === 0) {
          related.item.style.height = `${related.height}px`;
          related.item.classList.add("is-opened");
          this.classList.add("is-opened");
        } else {
          related.item.style.height = "0";
          related.item.classList.remove("is-opened");
          this.classList.remove("is-opened");
        }
      });
    });
  }

  init() {
    this.jobOffers();
    window.addEventListener("resize", () => {
      this.jobOffers();
    });
  }
}
