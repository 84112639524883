import { toArray } from "./utils";

const ALL_CATEGORY_NAME = "All";
const ACTIVE_CLASS = "is-active";
const HIDDEN_CLASS = "is-hidden";
const VISIBLE_CLASS = "is-visible";
const ITEMS_PER_PAGE = 3;
const BLOG_PAGINATION_NUMBER_CLASS = "js-blog-pagination-number";

export default class {
  constructor() {
    this.data = {
      posts: toArray(document.querySelectorAll(".js-blog-post")),
      categories: toArray(document.querySelectorAll(".js-blog-category")),
      emptyNotification: document.querySelector(".js-blog-posts-empty"),
      pagination: document.querySelector(".js-blog-pagination"),
      paginationPrev: document.querySelector(".js-blog-pagination-prev"),
      paginationNext: document.querySelector(".js-blog-pagination-next"),
      paginationNumbers: document.querySelector(".js-blog-pagination-numbers"),
      currentPage: 1,
    };

    this.data.postsNr = this.data.posts.length;
    this.data.visiblePosts = this.data.posts;

    this.setupCategories();
    this.setupPagination();
  }

  categoryChange(category) {
    const { name: activeCategoryName } = category.dataset;

    this.data.categories.forEach((item) => {
      item.classList.remove(ACTIVE_CLASS);
    });

    category.classList.add(ACTIVE_CLASS);

    // Filter posts
    this.data.visiblePosts = [];

    this.data.posts.forEach((post) => {
      const { category } = post.dataset;

      if (
        ALL_CATEGORY_NAME === activeCategoryName ||
        category === activeCategoryName
      ) {
        post.classList.remove(HIDDEN_CLASS);

        this.data.visiblePosts.push(post);
      } else {
        post.classList.add(HIDDEN_CLASS);
      }
    });

    // Update empty notification
    if (this.data.visiblePosts.length === 0) {
      this.data.emptyNotification.classList.add(VISIBLE_CLASS);
    } else {
      this.data.emptyNotification.classList.remove(VISIBLE_CLASS);
    }

    // Update pagination
    this.renderPagination();
  }

  setupCategories() {
    this.data.categories.forEach((category) => {
      category.addEventListener("click", () => this.categoryChange(category));
    });
  }

  renderPagination() {
    this.data.pageNumber = Math.ceil(
      this.data.visiblePosts.length / ITEMS_PER_PAGE,
    );

    if (this.data.pageNumber < 2) {
      if (this.data.pagination) {
        this.data.pagination.classList.add(HIDDEN_CLASS);
      }

      return;
    }

    if (this.data.pagination) {
      this.data.pagination.classList.remove(HIDDEN_CLASS);
    }
    this.data.paginationNumbers.innerHTML = "";

    // Render numbers
    for (let i = 1; i <= this.data.pageNumber; i++) {
      this.data.paginationNumbers.innerHTML += `
          <button class="${BLOG_PAGINATION_NUMBER_CLASS}" type="button" data-page="${i}">${i}</button>
      `;
    }

    document
      .querySelectorAll(`.${BLOG_PAGINATION_NUMBER_CLASS}`)
      .forEach((button) => {
        button.addEventListener("click", () => {
          let { page } = button.dataset;
          page = parseInt(page, 10);

          if (page !== this.data.currentPage) {
            this.changePage(page);
          }
        });
      });

    this.changePage(1);
  }

  changePage(page) {
    this.data.currentPage = page;

    const firstIndex = (this.data.currentPage - 1) * ITEMS_PER_PAGE;
    const lastIndex = this.data.currentPage * ITEMS_PER_PAGE;

    this.data.visiblePosts.forEach((post, index) => {
      if (firstIndex <= index && index < lastIndex) {
        post.classList.remove(HIDDEN_CLASS);
      } else {
        post.classList.add(HIDDEN_CLASS);
      }
    });

    document
      .querySelectorAll(`.${BLOG_PAGINATION_NUMBER_CLASS}`)
      .forEach((number, index) => {
        if (index + 1 === this.data.currentPage) {
          number.classList.add(ACTIVE_CLASS);
        } else {
          number.classList.remove(ACTIVE_CLASS);
        }
      });
  }

  goPrevPage() {
    if (this.data.currentPage > 1) {
      const page = this.data.currentPage - 1;

      this.changePage(page);
    }
  }

  goNextPage() {
    if (this.data.currentPage < this.data.pageNumber) {
      const page = this.data.currentPage + 1;

      this.changePage(page);
    }
  }

  setupPagination() {
    if (this.data.paginationPrev) {
      this.data.paginationPrev.addEventListener("click", () =>
        this.goPrevPage(),
      );
    }

    if (this.data.paginationNext) {
      this.data.paginationNext.addEventListener("click", () =>
        this.goNextPage(),
      );
    }

    this.renderPagination();
  }
}
