import "requestidlecallback-polyfill";

let resolve;

const recaptchaPromise = new Promise((res) => {
  resolve = res;
});

let recaptchaAdded = false;

window.recaptchaLoaded = () => {
  resolve(window.grecaptcha);
};

export function loadRecaptcha() {
  if (recaptchaAdded) {
    return recaptchaPromise;
  }

  window.requestIdleCallback(
    () => {
      const script = document.createElement("script");
      script.src =
        "https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit";
      script.async = true;

      document.head.appendChild(script);
    },
    { timeout: 1000 },
  );

  recaptchaAdded = true;
  return recaptchaPromise;
}

export function setupRecaptcha(form, successCallback = null, errorCallback) {
  const siteKey = window.RECAPTCHA_SITE_KEY;
  const callback =
    successCallback ||
    (() => {
      form.submit();
    });
  const eCallback = errorCallback || (() => {});

  if (!siteKey) {
    console.warn("No reCAPTCHA site key specified!");
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      successCallback();
    });
    return;
  }

  const recaptchaContainer = document.createElement("div");
  form.appendChild(recaptchaContainer);

  recaptchaPromise.then((grecaptcha) => {
    const recaptchaId = grecaptcha.render(recaptchaContainer, {
      sitekey: siteKey,
      size: "invisible",
      badge: "bottomright",
      callback: (responseToken) => {
        // submit request to lambda to see if recaptcha is valid
        fetch("/.netlify/functions/recaptcha", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: responseToken,
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.success) {
              callback(result.userIP);
            } else {
              grecaptcha.reset(recaptchaId);
              eCallback();
            }
          });
      },
    });

    form.addEventListener("submit", (e) => {
      e.preventDefault();

      recaptchaPromise.then((grecaptcha) => {
        grecaptcha.execute(recaptchaId);
      });
    });
  });
}
