import { loadRecaptcha, setupRecaptcha } from "./recaptcha";

import {
  REQUEST_VALUES,
  getFormData,
  getResponseErrorMessage,
  getCookie,
} from "./hubspot-form-helpers";

const WRAPPER = ".js-newsletter-form";
const RESPONSE = ".js-newsletter-form-response";
const FORM = ".js-newsletter-form-form";
const SUCCESS_MESSAGE = ".js-newsletter-response-success";
const ERROR_MESSAGE = ".js-newsletter-response-error";
const FORM_ID = window.HUBSPOT_NEWSLETTER_FORM_ID;
const { HUBSPOT_PORTAL_ID } = window;

export default class {
  constructor() {
    this.wrapper = document.querySelector(WRAPPER);
    if (!this.wrapper) {
      return;
    }

    const response = this.wrapper.querySelector(RESPONSE);
    this.form = this.wrapper.querySelector(FORM);
    this.messageSuccess = response.querySelector(SUCCESS_MESSAGE);
    this.messageError = response.querySelector(ERROR_MESSAGE);
    this.activeNotification = false;

    const obs = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadRecaptcha();
            observer.unobserve(entry.target);
          }
        });
      },
      {
        rootMargin: "100px 0px",
      },
    );
    obs.observe(this.wrapper);

    setupRecaptcha(
      this.form,
      (ip) => {
        const data = getFormData(this.form);
        this.submitForm(ip, data);
      },
      () => {
        this.messageError.textContent =
          "reCAPTCHA error occurred. Please try again.";
        this.messageError.style.display = "";
      },
    );
  }

  submitForm(ip, formData) {
    const hutk = getCookie("hubspotutk");

    const xhr = new XMLHttpRequest();
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${FORM_ID}`;
    let data = {};

    data = formData;

    const requestData = {
      fields: [
        {
          name: "firstname",
          value: data.FNAME,
        },
        {
          name: "lastname",
          value: data.LNAME,
        },
        {
          name: "email",
          value: data.EMAIL,
        },
        {
          name: "jobtitle",
          value: data.jobtitle,
        },
        {
          name: "company",
          value: data.company,
        },
        {
          name: "state",
          value: data.state,
        },
        {
          name: "country",
          value: data.country,
        },
      ],
      context: {
        hutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: window.location.href,
        pageName: `${this.form.dataset.modal === "true" ? "Modal on " : ""}${
          document.title
        }`,
        ipAddress: ip,
      },
      legalConsentOptions: {
        consent: {
          // Include this object when GDPR options are enabled
          consentToProcess: true,
          text: "I agree to allow Traverse System Company to store and process my personal data.",
        },
      },
    };

    const final_data = JSON.stringify(requestData);

    if (this.activeNotification) {
      this.activeNotification.style.display = "none";
    }

    xhr.open("POST", url);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = () => {
      let message;

      if (xhr.readyState == REQUEST_VALUES.READY_STATE_DONE) {
        if (xhr.status == REQUEST_VALUES.STATUS_DONE) {
          this.activeNotification = this.messageSuccess;
          message = "Thank you for subscribing!";

          if (this.form.dataset.modal === "true") {
            document.dispatchEvent(
              new window.CustomEvent("modal-form-submitted"),
            );
          }

          window.gtagAds("event", "conversion", {
            send_to: "AW-462686462/PsSJCNnT9t0CEP6R0NwB",
          });
        } else {
          /*
              Request States:
              400 - submission is rejected
              403 - the portal isn't allowed to post submissions
              404 - the formGuid isn't found
            */
          console.error(xhr.responseText);
          this.activeNotification = this.messageError;
          const responseJSON = JSON.parse(xhr.responseText);
          message = getResponseErrorMessage(responseJSON);
        }

        this.activeNotification.style.display = "";
        this.activeNotification.textContent = message;
      }
    };

    xhr.send(final_data);
  }
}
