const TOGGLE_COMPONENT = "[data-share]";
const OPENED_CLASS = "is-opened";
const MAIL_TO_ELEMENT = "[data-share-mail-to]";
const COPY_LINK_ELEMENT = "[data-share-copy-link]";

export default class {
  constructor() {
    this.DOM = {
      buttons: document.querySelectorAll(TOGGLE_COMPONENT),
      mailToButtons: document.querySelectorAll(MAIL_TO_ELEMENT),
      copyLinkButtons: document.querySelectorAll(COPY_LINK_ELEMENT),
    };

    if (this.DOM.buttons) {
      this.DOM.buttons.forEach((btn) => {
        btn.addEventListener("click", () => {
          btn.classList.toggle(OPENED_CLASS);
        });
      });
    }

    this.initializeMailTo();
    this.initializeCopyLink();
  }

  initializeMailTo() {
    if (this.DOM.mailToButtons && this.DOM.mailToButtons.length) {
      this.DOM.mailToButtons.forEach((item) => {
        item.addEventListener("click", (e) => {
          e.preventDefault();
          window.location.href = `mailto:?subject=Check out this link!&body=Check out this link! - ${document.location.href}`;
        });
      });
    }
  }

  initializeCopyLink() {
    if (this.DOM.copyLinkButtons && this.DOM.copyLinkButtons.length) {
      this.DOM.copyLinkButtons.forEach((item) => {
        item.addEventListener("click", (e) => {
          e.preventDefault();

          if (navigator.clipboard) {
            navigator.clipboard.writeText(window.location.href);
          } else {
            const tmpInput = document.createElement("input");
            document.body.appendChild(tmpInput);
            tmpInput.value = document.location.href;
            tmpInput.select();
            document.execCommand("copy");
            document.body.removeChild(tmpInput);
          }
        });
      });
    }
  }
}
