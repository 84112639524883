export default class {
  constructor() {
    this.data = {
      progressBar: document.querySelector("#progress-bar"),
      section: document.querySelector("#post-content"),
    };

    if (!this.data.progressBar && this.data.section) {
      return;
    }

    this.init();
  }

  init() {
    document.addEventListener("scroll", () => this.processScroll());
  }

  processScroll() {
    const scrollDistance = -this.data.section.getBoundingClientRect().top;
    const progressPercentage =
      (scrollDistance /
        (this.data.section.getBoundingClientRect().height -
          document.documentElement.clientHeight)) *
      100;

    this.data.progressBar.style.setProperty(
      "--scroll-amount",
      `${progressPercentage < 0 ? 0 : progressPercentage}%`,
    );
  }
}
