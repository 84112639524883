export const FORM_ERRORS = {
  INVALID_EMAIL:
    "This email address looks fake or invalid. Please enter a real email address.",
  REQUIRED_FIELD: "Please fill out all required fields.",
  DEFAULT_ERROR: "whoops, something went wrong.",
};

export const REQUEST_VALUES = {
  READY_STATE_DONE: 4,
  STATUS_DONE: 200,
};

/**
 * Convert form's data into object
 * @param {form} DOM Form element
 */
export function getFormData(form) {
  const formData = new FormData(form);
  const data = {};

  formData.forEach((value, key) => {
    data[key] = value;
  });

  return data;
}

/**
 * Display user friendly error message
 * @param {response} JSON Server's response
 */
export function getResponseErrorMessage(response) {
  if (!response.errors) {
    return false;
  }

  let errorMessage;

  response.errors.forEach((error) => {
    errorMessage = FORM_ERRORS[error.errorType]
      ? FORM_ERRORS[error.errorType]
      : "whoops, something went wrong.";
  });

  return errorMessage;
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
