import { toArray } from "./utils";

const CALLBACK_FALLBACK_DELAY = 3000;

export default class ConversionTracking {
  constructor() {
    this.conversionTrackingNavigationLinks = toArray(
      document.querySelectorAll("a[data-gtag-report-conversion]"),
    );

    this.setup();
  }

  setup() {
    this.conversionTrackingNavigationLinks.forEach((el) => {
      el.addEventListener("click", (ev) => {
        const sendTo = el.getAttribute("data-gtag-report-conversion");
        if (!sendTo) {
          return;
        }

        let called = false;
        const shouldWait = el.getAttribute("target") !== "_blank";
        const callback = () => {
          if (called) {
            return;
          }

          called = true;

          if (shouldWait) {
            window.location = el.getAttribute("href");
            // console.log("window.location");
          }
        };

        if (shouldWait) {
          ev.preventDefault();
        } else {
          //
        }

        setTimeout(callback, CALLBACK_FALLBACK_DELAY);
        window.gtagAds("event", "conversion", {
          send_to: sendTo,
          event_callback: callback,
        });
      });
    });
  }
}
