const COMPONENT = "[data-hamburger]";
const OPENED_CLASS = "has-menu-opened";

export default class {
  constructor() {
    this.DOM = {
      component: document.querySelector(COMPONENT),
    };

    if (this.DOM.component) {
      this.DOM.component.addEventListener("click", () => {
        document.documentElement.classList.toggle(OPENED_CLASS);
      });
    }
  }
}
