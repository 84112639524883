export default class {
  constructor() {
    this.button = document.querySelector(".js-resources-case-studies-expand");
    this.wrapperExpand = document.querySelector(
      ".js-resources-case-studies-wrapper-expand",
    );
    this.wrapperToExpand = document.querySelector(
      ".js-resources-case-studies-wrapper-to-expand",
    );

    if (!(this.button && this.wrapperExpand && this.wrapperToExpand)) {
      return;
    }

    this.setup();
  }

  setup() {
    this.button.addEventListener("click", (e) => {
      e.preventDefault();
      this.expand();
    });
  }

  expand() {
    const { wrapperExpand, wrapperToExpand } = this;

    wrapperToExpand.style.height = "";
    wrapperToExpand.style.visibility = "inherit";

    const toExpandHeight = wrapperToExpand.scrollHeight;

    wrapperToExpand.style.height = "0";
    wrapperExpand.style.height = `${wrapperExpand.scrollHeight}px`;

    // eslint-disable-next-line no-unused-expressions
    window.getComputedStyle(wrapperExpand).transform;

    wrapperToExpand.style.height = `${toExpandHeight}px`;
    wrapperExpand.style.height = "0";

    wrapperExpand.addEventListener("transitionend", (e) => {
      if (e.propertyName === "height") {
        wrapperExpand.style.visibility = "hidden";
      }
    });

    wrapperToExpand.addEventListener("transitionend", (e) => {
      if (e.propertyName === "height") {
        wrapperToExpand.style.height = "";
      }
    });
  }
}
