/* eslint-disable no-new */

/*
  Project: Traverse
  Author: Xfive
 */

import "./vendor/modernizr-webp";

import Menu from "./modules/menu";
import Notifications from "./modules/notifications";
// import ContactForm from "./modules/contact-form";
import NewsletterForm from "./modules/newsletter-form";
import NetlifyIdentityRedirect from "./modules/netlify-identity-redirect";
import News from "./modules/news";
import CaseStudies from "./modules/case-studies";
import NewsletterModal from "./modules/newsletter-modal";
import Jobs from "./modules/jobs";
import ConversionTracking from "./modules/conversion-tracking";
import ReadingProgress from "./modules/reading-progress";
import Share from "./modules/share";
import Blog from "./modules/blog";

new Menu();
new Notifications();
// new ContactForm();
new NetlifyIdentityRedirect();
new News();
new CaseStudies();
new NewsletterModal();
new Jobs();
new NewsletterForm();
new ConversionTracking();
new ReadingProgress();
new Share();
new Blog();
